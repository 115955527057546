import {Injectable} from '@angular/core';
import {Repository} from "../persistence/repository";
import {Moment} from "moment";
import moment from "moment/moment";
import {Entity} from "../model/entity";
import {FirestoreWrapper} from "../persistence/firestoreWrapper";
import {persist, PersistenceConfig} from "../persistence/decorators";
import {ConverterRegistry} from "../persistence/converter-registry.service";

@Injectable({
    providedIn: 'root'
})
export class ContactRequestRepository extends Repository<ContactRequest> {

    constructor(firestore: FirestoreWrapper) {
        super(firestore, ConverterRegistry.find('ContactRequest'))
    }

    override getCollectionReference() {
        return this.firestoreWrapper.collection(`contactRequests`).withConverter(this.converter);
    }

}

@persist(new PersistenceConfig({
    name: 'ContactRequest',
    properties: ['name', 'email', 'date', 'package', 'description']
}))
export class ContactRequest extends Entity {
    name: string
    email: string
    date: Moment = moment()
    package: string | null = null
    description: string = ''
    lastname: string | null = null;
}
